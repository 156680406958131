import React from 'react';
import { Helmet } from 'react-helmet';
import porticaImage from '../../images/portica-image.png';

export default function SEO({ title, keywords, description, image }) {
  const altTitle = 'Portica - All your projects in one place';
  const altDesc =
    'All-in-one project manager, created by Architects for Architects and Design Professionals. A digital home for your projects. Manage your projects without different sheets or apps. Connect the clients, contractors, colleagues, and deliverables that you are responsible for';
  const altKeywords =
    'architecture, architect, projects, project manager, projects management, contacts, tasks, todo, todo app, list';
  const altImage = porticaImage;

  return (
    <Helmet>
      <title>{title || altTitle}</title>
      <meta name="description" content={description || altDesc} />
      <meta name="keywords" content={keywords || altKeywords} />
      <meta name="image" content={image || altImage} />

      <meta property="og:title" content={title || ''} />
      <meta property="og:type" content={'article'} />
      <meta property="og:description" content={description || altDesc} />
      <meta property="og:image" content={image || altImage} />

      <meta property="twitter:title" content={title || ''} />
      <meta property="twitter:description" content={description || altDesc} />
      <meta property="twitter:image" content={image || altImage} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta name="robots" content="index,follow" />
    </Helmet>
  );
}
