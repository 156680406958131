import * as CookieSvc from './cookie';

export const CONSENT_COOKIE = 'ppro-consent';
export const CONSENT_OPTIONS = ['all', 'functional', 'none'];

export const getConsent = () => {
  return CookieSvc.get(CONSENT_COOKIE);
};

export const setConsent = (data) => {
  const current = new Date();
  const nextYear = new Date();

  nextYear.setFullYear(current.getFullYear() + 1);

  var options = {
    path: '/',
    expires: nextYear,
    domain: '.' + process.env.GATSBY_SITE_DOMAIN,
  };

  return CookieSvc.set(CONSENT_COOKIE, data, options);
};

export const clearConsent = () => {
  return CookieSvc.destroy(CONSENT_COOKIE);
};
