import Cookies from 'universal-cookie';

const cookies = new Cookies();
const COOKIE_NAME = process.env.GATSBY_COOKIE_NAME;

export const getLoginCookie = () => {
  return get(COOKIE_NAME);
};

export const get = (name) => {
  return cookies.get(name);
};

export const set = (name, val, options) => {
  const opts = options || {};
  return cookies.set(name, val, opts);
};

export const destroy = (name) => {
  return cookies.remove(name);
};
